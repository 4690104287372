import React, { useState, useEffect, useCallback, useMemo } from "react";
import { createClient } from "@supabase/supabase-js";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import { useDropzone } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { GlobalStyles } from "@mui/material";

// Accessing the environment variables
const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || "";
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY || "";

const supabase = createClient(supabaseUrl, supabaseAnonKey);

function App() {
  const [session, setSession] = useState<any>(null);
  const [images, setImages] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [prompt, setPrompt] = useState("");
  const [email, setEmail] = useState("");
  const [darkMode, setDarkMode] = useState(true);
  const [loading, setLoading] = useState(true);
  const [emailSent, setEmailSent] = useState(false);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
        setLoading(false);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  async function signInWithGoogle() {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: redirectUri,
      },
    });
    if (error) {
      console.error("Error signing in with Google:", error);
      setLoading(false);
    }
  }

  async function signInWithEmail(email: string) {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOtp({
      email,
    });
    if (error) {
      console.error("Error signing in with Email:", error);
      setLoading(false);
    } else {
      setEmailSent(true);
      setLoading(false);
    }
  }

  async function signOut() {
    setLoading(true);
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error);
      setLoading(false);
    }
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeFile = useCallback((index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }, []);

  async function uploadImages() {
    setLoading(true);
    for (const file of uploadedFiles) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch("YOUR_CLOUDFLARE_UPLOAD_URL", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Upload failed");
        }

        const result = await response.json();
        console.log("File uploaded successfully:", result);
        // You might want to update your images state here with the new URL
        setImages((prevImages) => [...prevImages, result.url]);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
    setUploadedFiles([]);
    setLoading(false);
  }

  async function generateImage() {
    // Implement AI image generation logic
  }

  async function fetchUserImages() {
    // Implement fetching user's images
  }

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Fade in={loading} unmountOnExit>
            <CircularProgress />
          </Fade>
        </Box>
      );
    }

    return (
      <Container
        maxWidth="lg"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        <Box sx={{ my: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src="/assets/logo.svg"
                alt="PhotoCraft.me Logo"
                style={{ width: "40px", marginRight: "10px" }}
              />
              <Typography variant="h6" component="h3">
                PhotoCraft.Me
              </Typography>
            </Box>
          </Box>
          {!session ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              {emailSent ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6">Check Your Email</Typography>
                  <Typography variant="body1">
                    We've sent you a login link. Please check your email {email}{" "}
                    and click the link to sign in.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Button
                    variant="contained"
                    onClick={signInWithGoogle}
                    fullWidth
                  >
                    Sign In with Google
                  </Button>
                  <Typography variant="body1">or</Typography>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                  />
                  <Button
                    variant="contained"
                    onClick={() => signInWithEmail(email)}
                    fullWidth
                  >
                    Sign In with Email
                  </Button>
                </>
              )}
            </Box>
          ) : (
            <Box>
              <Button variant="contained" onClick={signOut} fullWidth>
                Sign Out
              </Button>
              <Box component="main" sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Upload Images
                </Typography>
                <Box
                  {...getRootProps()}
                  sx={{
                    border: "2px dashed grey",
                    borderRadius: "4px",
                    p: 2,
                    mb: 2,
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  )}
                </Box>
                {uploadedFiles.length > 0 && (
                  <Box sx={{ mb: 2, width: "100%" }}>
                    <Typography variant="subtitle1">Selected Files:</Typography>
                    <Grid container spacing={2}>
                      {uploadedFiles.map((file, index) => (
                        <Grid item xs={4} key={file.name + index}>
                          <Box
                            sx={{
                              position: "relative",
                              paddingTop: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <IconButton
                              onClick={() => removeFile(index)}
                              size="small"
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Tooltip title={file.name} placement="bottom">
                              <Typography
                                variant="caption"
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  color: "white",
                                  padding: "4px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {file.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
                <Button
                  variant="contained"
                  onClick={uploadImages}
                  fullWidth
                  disabled={uploadedFiles.length === 0}
                >
                  Upload Images
                </Button>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Generate AI Image
                </Typography>
                <TextField
                  fullWidth
                  value={prompt}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPrompt(e.target.value)
                  }
                  placeholder="Enter your prompt"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" onClick={generateImage} fullWidth>
                  Generate
                </Button>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Your Images
                </Typography>
                <Box sx={{ width: "100%", overflow: "hidden" }}>
                  <Grid container spacing={2}>
                    {images.map((image, index) => (
                      <Grid item xs={4} key={image + index}>
                        <Box
                          sx={{
                            position: "relative",
                            paddingTop: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={image}
                            alt={`Generated ${index}`}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <Tooltip
                            title={`Generated ${index}`}
                            placement="bottom"
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                                padding: "4px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {`Generated ${index}`}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          html: darkMode ? { colorScheme: "dark" } : {},
        }}
      />
      {renderContent()}
    </ThemeProvider>
  );
}

export default App;
